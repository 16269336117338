<template>
  <div class="container is-fluid mt-3 pb-5">
    <separator class="separator is-primary"></separator>
    <div class="is-size-2 has-text-weight-bold has-text-centered mb-2">{{ $t("invoice_generator.title") }}</div>

    <div class="columns">
      <div class="column is-half">
        <div class="box">
          <div class="is-size-4 has-text-centered">{{ $t("invoice_generator.settings_title") }}</div>
          <div class="box">
            <nav-locales></nav-locales>
          </div>
          <div class="field mt-2">
            <label class="label has-text-primary">{{ $t("invoice_generator.settings_currency") }}</label>
            <div class="control">
              <input class="input is-simple" type="text" v-model="currency"/>
            </div>
          </div>

          <div class="field mb-1">
            <div class="control">
              <label class="checkbox consents">
                <checkbox class="checkbox-input" v-model="isCorrection"></checkbox>
                <i18n
                  path="invoice_generator.settings_invoice_correction"
                  tag="span"
                  class="consents"
                  @click="isCorrection = !isCorrection"
                ></i18n>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="column is-half">
        <div class="box">
          <label class="upload-button-container is-relative" v-if="!invoiceArrays">
            <add-icon class="add-icon mr-1"></add-icon>
            <div class="add-title has-text-secondary">{{ $t("invoice_generator.upload_csv_file") }}</div>
            <input type="file" accept=".csv" @change="onNewCsvFile" style="visibility: hidden; width: 0; height: 0"/>
          </label>

          <div v-else>
            <b-button type="is-primary" class="has-fullwidth" @click="generateSingleInvoices">{{
                $t("invoice_generator.download_current_invoice")
              }}
            </b-button>
            <b-button type="is-primary" class="has-fullwidth mt-2" @click="generateAllInvoices(true)">{{
                $t("invoice_generator.download_all_invoices")
              }}
            </b-button>
            <div class="mt-2">
              <div class="is-size-5">{{ $t("invoice_generator.file_information") }}</div>
              <div>{{ `${$t("invoice_generator.number_of_invoices")} ${invoicesCount}` }}</div>
            </div>
            <div v-if="error">
              <div class="is-size-4">{{ $t("invoice_generator.invoice_file_errors") }}</div>
              <div>{{ error }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="invoiceArrays">
      <div class="has-fullwidth has-text-centered">
        <div class="is-size-2">{{ $t("invoice_generator.invoice_preview") }}</div>
        <div class="is-flex is-flex-direction-row has-fullwidth is-align-items-center is-justify-content-center">
          <b-button type="is-primary" class="mx-1" @click="previousInvoice" :disabled="currentInvoiceIndex === 0">{{
              $t("invoice_generator.previous_button")
            }}
          </b-button>
          <b-button type="is-primary" @click="nextInvoice" :disabled="currentInvoiceIndex === invoiceArrays.length - 1">
            {{
              $t("invoice_generator.next_button")
            }}
          </b-button>
        </div>
        <div>{{
            `${$t("invoice_generator.current_invoice")} ${this.currentInvoiceIndex + 1}/${this.invoiceArrays.length}`
          }}
        </div>
      </div>
      <div class="box has-fullwidth mt-3">
        <div ref="invoice">
          <div class="columns mb-0">
            <div class="column is-half" v-if="!isCorrection">
              <div>
                <div>
                  <span class="invoice-bold-field">{{ $t("invoice_generator.invoice_field_number") }}&nbsp;</span>
                  <span class="invoice-field">{{ invoiceNumber }}</span>
                </div>

                <div class="mt-2">
                  <span class="invoice-bold-field">{{
                      $t("invoice_generator.invoice_field_date_of_issue")
                    }}&nbsp;</span>
                  <span class="invoice-field">{{ invoiceIssueDate }}</span>
                </div>

                <div v-if="invoiceDeliveryDate">
                  <span class="invoice-bold-field">{{
                      $t("invoice_generator.invoice_field_date_of_receipt")
                    }}&nbsp;</span>
                  <span class="invoice-field">{{ invoiceDeliveryDate }}</span>
                </div>

                <div>
                  <span class="invoice-bold-field">{{
                      $t("invoice_generator.invoice_field_date_of_payment")
                    }}&nbsp;</span>
                  <span class="invoice-field">{{ invoicePaymentDate }}</span>
                </div>

                <div>
                  <span class="invoice-bold-field">{{
                      $t("invoice_generator.invoice_field_payment_operator")
                    }}&nbsp;</span>
                  <span class="invoice-field">{{ paymentName }}</span>
                </div>
              </div>
            </div>
            <div class="column is-half" v-else>
              <div>
                <div>
                  <span class="invoice-bold-field">{{ $t("invoice_generator.invoice_correction") }}&nbsp;</span>
                  <span class="invoice-field">{{ invoiceCorrectionNumber }}</span>
                </div>

                <div>
                  <i18n path="invoice_generator.invoice_correction_related_number" tag="span"
                        class="invoice-bold-field">
                    <span class="invoice-field has-text-weight-normal">{{ invoiceNumber }}</span>
                    <span class="invoice-field has-text-weight-normal">{{ invoiceIssueDate }}</span>
                  </i18n>
                </div>

                <div class="mt-2">
                  <span class="invoice-bold-field">{{
                      $t("invoice_generator.invoice_field_date_of_issue")
                    }}&nbsp;</span>
                  <span class="invoice-field">{{ invoiceCorrectionIssueDate }}</span>
                </div>

                <div v-if="invoiceCorrectionDeliveryDate">
                  <span class="invoice-bold-field">{{
                      $t("invoice_generator.invoice_field_date_of_receipt")
                    }}&nbsp;</span>
                  <span class="invoice-field">{{ invoiceCorrectionDeliveryDate }}</span>
                </div>

                <div>
                  <span class="invoice-bold-field">{{
                      $t("invoice_generator.invoice_field_date_of_payment")
                    }}&nbsp;</span>
                  <span class="invoice-field">{{ invoiceCorrectionPaymentDate }}</span>
                </div>

                <div>
                  <span class="invoice-bold-field">{{
                      $t("invoice_generator.invoice_field_payment_operator")
                    }}&nbsp;</span>
                  <span class="invoice-field">{{ paymentCorrectionName }}</span>
                </div>
              </div>
            </div>
            <div class="column is-half">
              <figure class="image is-200x200 is-centered mx-auto">
                <img src="@/assets/invoice/invoice_ma_logo.png"/>
              </figure>
            </div>
          </div>
          <div class="horizontal-divider mt-1"></div>

          <div class="columns mt-1 mb-0">
            <div class="column is-half">
              <div>
                <div class="invoice-bold-field">{{ $t("invoice_generator.invoice_field_seller") }}</div>
                <div class="mt-1">
                  <div class="invoice-field">{{ sellerName }}</div>
                  <div class="invoice-field">{{ sellerAddressFirstLine }}</div>
                  <div class="invoice-field">{{ sellerAddressSecondLine }}</div>
                  <div class="invoice-field" v-if="sellerNIP">
                    {{ `${$t("invoice_generator.invoice_field_nip")} ${sellerNIP}` }}
                  </div>
                </div>
              </div>
            </div>
            <div class="column is-half">
              <div>
                <div class="invoice-bold-field">{{ $t("invoice_generator.invoice_field_buyer") }}</div>
                <div class="mt-1">
                  <div class="invoice-field">{{ buyerName }}</div>
                  <div class="invoice-field">{{ buyerAddressFirstLine }}</div>
                  <div class="invoice-field">{{ buyerAddressSecondLine }}</div>
                  <div class="invoice-field" v-if="buyerNIP">
                    {{ `${$t("invoice_generator.invoice_field_nip")} ${buyerNIP}` }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <template v-if="!isCorrection || true">
            <table class="table is-bordered is-fullwidth">
              <thead>
              <tr class="invoice-bold-field">
                <th class="has-text-right">
                  <abbr>{{ $t("invoice_generator.invoice_field_table_index") }}</abbr>
                </th>
                <th>
                  <abbr>{{ $t("invoice_generator.invoice_field_product_name") }}</abbr>
                </th>
                <th class="has-text-right">
                  <abbr>{{ $t("invoice_generator.invoice_field_product_quantity") }}</abbr>
                </th>
                <th class="has-text-right">
                  <abbr>{{ $t("invoice_generator.invoice_field_product_net_price") }}</abbr>
                </th>
                <th class="has-text-right">
                  <abbr>{{ $t("invoice_generator.invoice_field_product_net_value") }}</abbr>
                </th>
                <th class="has-text-right">
                  <abbr>{{ $t("invoice_generator.invoice_field_product_vat") }}</abbr>
                </th>
                <th class="has-text-right">
                  <abbr>{{ $t("invoice_generator.invoice_field_product_vat_value") }}</abbr>
                </th>
                <th class="has-text-right">
                  <abbr>{{ $t("invoice_generator.invoice_field_product_gross_value") }}</abbr>
                </th>
              </tr>
              </thead>
              <tbody class="invoice-field">
              <tr>
                <td class="has-text-right">{{ ticketLp }}</td>
                <td>{{ ticketName }}</td>
                <td class="has-text-right">{{
                    `${itemsCount} ${$t("invoice_generator.invoice_field_product_pieces")}`
                  }}
                </td>
                <td class="has-text-right">{{ pretaxPrice.toFixed(2) }}</td>
                <td class="has-text-right">{{ pretaxValue.toFixed(2) }}</td>
                <td class="has-text-right">{{ taxFactor }}</td>
                <td class="has-text-right">{{ taxValue.toFixed(2) }}</td>
                <td class="has-text-right">{{ bruttoValue.toFixed(2) }}</td>
              </tr>
              <tr class="has-text-weight-bold">
                <td class="empty-cell"></td>
                <td class="empty-cell"></td>
                <td class="empty-cell"></td>
                <td class="has-text-right">{{ $t("invoice_generator.invoice_field_product_sum") }}</td>
                <td class="has-text-right">{{ pretaxValue.toFixed(2) }}</td>
                <td class="has-text-right">{{ taxFactor }}</td>
                <td class="has-text-right">{{ taxValue.toFixed(2) }}</td>
                <td class="has-text-right">{{ bruttoValue.toFixed(2) }}</td>
              </tr>
              </tbody>
            </table>
          </template>
          <template v-else>
            <div class="invoice-bold-field">
              {{ $t("invoice_generator.invoice_before_correction") }}
            </div>
            <table class="table is-bordered is-fullwidth mt-1">
              <thead>
              <tr class="invoice-bold-field">
                <th class="has-text-right">
                  <abbr>{{ $t("invoice_generator.invoice_field_table_index") }}</abbr>
                </th>
                <th>
                  <abbr>{{ $t("invoice_generator.invoice_field_product_name") }}</abbr>
                </th>
                <th class="has-text-right">
                  <abbr>{{ $t("invoice_generator.invoice_field_product_quantity") }}</abbr>
                </th>
                <th class="has-text-right">
                  <abbr>{{ $t("invoice_generator.invoice_field_product_net_price") }}</abbr>
                </th>
                <th class="has-text-right">
                  <abbr>{{ $t("invoice_generator.invoice_field_product_net_value") }}</abbr>
                </th>
                <th class="has-text-right">
                  <abbr>{{ $t("invoice_generator.invoice_field_product_vat") }}</abbr>
                </th>
                <th class="has-text-right">
                  <abbr>{{ $t("invoice_generator.invoice_field_product_vat_value") }}</abbr>
                </th>
                <th class="has-text-right">
                  <abbr>{{ $t("invoice_generator.invoice_field_product_gross_value") }}</abbr>
                </th>
              </tr>
              </thead>
              <tbody class="invoice-field">
              <tr>
                <td class="has-text-right">{{ ticketLp }}</td>
                <td>{{ ticketName }}</td>
                <td class="has-text-right">{{
                    `${itemsCount} ${$t("invoice_generator.invoice_field_product_pieces")}`
                  }}
                </td>
                <td class="has-text-right">{{ pretaxPrice.toFixed(2) }}</td>
                <td class="has-text-right">{{ pretaxValue.toFixed(2) }}</td>
                <td class="has-text-right">{{ taxFactor }}</td>
                <td class="has-text-right">{{ taxValue.toFixed(2) }}</td>
                <td class="has-text-right">{{ bruttoValue.toFixed(2) }}</td>
              </tr>
              </tbody>
            </table>

            <div class="invoice-bold-field mt-2">
              {{ $t("invoice_generator.invoice_after_correction") }}
            </div>
            <table class="table is-bordered is-fullwidth mt-1">
              <thead>
              <tr class="invoice-bold-field">
                <th class="has-text-right">
                  <abbr>{{ $t("invoice_generator.invoice_field_table_index") }}</abbr>
                </th>
                <th>
                  <abbr>{{ $t("invoice_generator.invoice_field_product_name") }}</abbr>
                </th>
                <th class="has-text-right">
                  <abbr>{{ $t("invoice_generator.invoice_field_product_quantity") }}</abbr>
                </th>
                <th class="has-text-right">
                  <abbr>{{ $t("invoice_generator.invoice_field_product_net_price") }}</abbr>
                </th>
                <th class="has-text-right">
                  <abbr>{{ $t("invoice_generator.invoice_field_product_net_value") }}</abbr>
                </th>
                <th class="has-text-right">
                  <abbr>{{ $t("invoice_generator.invoice_field_product_vat") }}</abbr>
                </th>
                <th class="has-text-right">
                  <abbr>{{ $t("invoice_generator.invoice_field_product_vat_value") }}</abbr>
                </th>
                <th class="has-text-right">
                  <abbr>{{ $t("invoice_generator.invoice_field_product_gross_value") }}</abbr>
                </th>
              </tr>
              </thead>
              <tbody class="invoice-field">
              <tr>
                <td class="has-text-right">{{ correctionTicketLp }}</td>
                <td>{{ correctionTicketName }}</td>
                <td class="has-text-right">
                  {{ `${correctionItemsCount} ${$t("invoice_generator.invoice_field_product_pieces")}` }}
                </td>
                <td class="has-text-right">{{ correctionPretaxPrice.toFixed(2) }}</td>
                <td class="has-text-right">{{ correctionPretaxValue.toFixed(2) }}</td>
                <td class="has-text-right">{{ correctionTaxFactor }}</td>
                <td class="has-text-right">{{ correctionTaxValue.toFixed(2) }}</td>
                <td class="has-text-right">{{ correctionBruttoValue.toFixed(2) }}</td>
              </tr>
              </tbody>
            </table>

            <div class="is-flex is-flex-direction-row is-justify-content-flex-end">
              <table class="table is-bordered mt-2">
                <thead>
                <tr class="invoice-bold-field">
                  <th class="empty-cell"></th>
                  <th class="has-text-right">
                    <abbr>{{ $t("invoice_generator.invoice_field_product_net_value") }}</abbr>
                  </th>
                  <th class="has-text-right">
                    <abbr>{{ $t("invoice_generator.invoice_field_product_vat") }}</abbr>
                  </th>
                  <th class="has-text-right">
                    <abbr>{{ $t("invoice_generator.invoice_field_product_vat_value") }}</abbr>
                  </th>
                  <th class="has-text-right">
                    <abbr>{{ $t("invoice_generator.invoice_field_product_gross_value") }}</abbr>
                  </th>
                </tr>
                </thead>
                <tbody class="invoice-field">
                <tr class="has-text-weight-bold">
                  <td class="has-text-right">{{ $t("invoice_generator.invoice_field_product_sum") }}</td>
                  <td class="has-text-right">{{ finalCorrectionPretaxValue.toFixed(2) }}</td>
                  <td class="has-text-right">{{ correctionTaxFactor }}</td>
                  <td class="has-text-right">{{ finalCorrectionTaxValue.toFixed(2) }}</td>
                  <td class="has-text-right">{{ finalCorrectionBruttoValue.toFixed(2) }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </template>

          <template v-if="!isCorrection">
            <div class="is-flex is-flex-direction-row is-justify-content-flex-end has-text-right mt-3"
                 v-if="!isCorrection">
              <div class="invoice-bold-field mr-3">
                <div>{{ $t("invoice_generator.invoice_field_product_net_value") }}</div>
                <div class="mt-1">{{ $t("invoice_generator.invoice_field_product_vat_value") }}</div>
                <div class="mt-1">{{ $t("invoice_generator.invoice_field_product_gross_value") }}</div>
              </div>
              <div class="invoice-field">
                <div>{{ `${pretaxValue.toFixed(2)} ${currency}` }}</div>
                <div class="mt-1">{{ `${taxValue.toFixed(2)} ${currency}` }}</div>
                <div class="mt-1">{{ `${bruttoValue.toFixed(2)} ${currency}` }}</div>
              </div>
            </div>

            <div class="horizontal-divider mt-4"></div>
            <div class="mt-1">
              <div class="invoice-bold-field">{{ $t("invoice_generator.invoice_field_remarks") }}</div>
              <div class="invoice-field">{{ invoiceAdditionInfo }}</div>
            </div>
            <div class="horizontal-divider mt-1"></div>
            <div class="mt-1">
              <div class="invoice-bold-field">{{ $t("invoice_generator.invoice_field_paid_amount") }}</div>
              <div class="invoice-field">{{ `${paidAmount.toFixed(2)} ${currency}` }}</div>
            </div>
            <div class="horizontal-divider mt-1"></div>
            <div class="mt-1">
              <div class="invoice-bold-field">{{ $t("invoice_generator.invoice_field_amount_to_be_paid") }}</div>
              <div class="invoice-field">{{ `${amountToBePaid.toFixed(2)} ${currency}` }}</div>
            </div>
            <div class="horizontal-divider mt-1"></div>
          </template>
          <template v-else>
            <div class="mt-4" v-if="finalCorrectionBruttoValue < 0">
              <div class="invoice-summary-bold-field">{{
                  `${$t("invoice_generator.invoice_correction_amount_to_be_returned")} ${finalCorrectionBruttoValue.toFixed(2)} ${currency}`
                }}
              </div>
              <div class="invoice-summary-bold-field">{{
                  `${$t("invoice_generator.invoice_correction_amount_returned")} ${correctionPaidAmount.toFixed(2)} ${currency}`
                }}
              </div>
              <div class="invoice-summary-bold-field">{{
                  `${$t("invoice_generator.invoice_correction_amount_left_to_be_returned")} ${correctionAmountToBePaid.toFixed(2)} ${currency}`
                }}
              </div>
            </div>
            <div v-else>
              <div class="invoice-summary-bold-field">{{
                  `${$t("invoice_generator.invoice_field_amount_to_be_paid")} ${finalCorrectionBruttoValue.toFixed(2)} ${currency}`
                }}
              </div>
              <div class="invoice-summary-bold-field">{{
                  `${$t("invoice_generator.invoice_field_paid_amount")} ${correctionPaidAmount.toFixed(2)} ${currency}`
                }}
              </div>
              <div class="invoice-summary-bold-field">{{
                  `${$t("invoice_generator.invoice_field_amount_left_to_be_paid")} ${correctionAmountToBePaid.toFixed(2)} ${currency}`
                }}
              </div>
            </div>
            <div class="horizontal-divider mt-1"></div>
            <div class="mt-1">
              <div>
                <span class="invoice-bold-field">{{ $t("invoice_generator.invoice_correction_tax_base_change_amount") }}&nbsp;</span>
                <span class="invoice-field">{{ `${finalCorrectionPretaxValue.toFixed(2)} ${currency}` }}</span>
              </div>
              <div>
                <span class="invoice-bold-field">{{ $t("invoice_generator.invoice_correction_amount_of_vat_change") }}&nbsp;</span>
                <span class="invoice-field">{{ `${finalCorrectionTaxValue.toFixed(2)} ${currency}` }}</span>
              </div>
            </div>
            <template v-if="invoiceAdditionInfo">
              <div class="horizontal-divider mt-4"></div>
              <div class="mt-1">
                <div class="invoice-bold-field">{{ $t("invoice_generator.invoice_field_remarks") }}</div>
                <div class="invoice-field">{{ invoiceAdditionInfo }}</div>
              </div>
            </template>
            <div class="horizontal-divider mt-1"></div>
            <div class="mt-1">
              <span class="invoice-bold-field">{{ $t("invoice_generator.invoice_correction_reason") }}&nbsp;</span>
              <span class="invoice-field">{{ invoiceCorrectionReason }}</span>
            </div>
            <div class="horizontal-divider mt-1"></div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Separator from "@/web/components/shared/Separator";
import AddIcon from "@/assets/icons/add_2.svg";

import html2pdf from "html2pdf.js";
import NavLocales from "@/web/components/locale/NavLocales";
import Checkbox from "@/shared/components/form/Checkbox";

const CsvParser = require("jquery-csv");

export default {
  name: "InvoiceGenerator",

  components: {NavLocales, Separator, AddIcon, Checkbox},

  data() {
    return {
      invoiceArrays: null,
      currentInvoiceIndex: 0,
      currency: "PLN",
      error: null,
      isCorrection: false,
    };
  },

  computed: {
    invoicesCount() {
      return this.invoiceArrays.length;
    },

    currentInvoice() {
      return this.invoiceArrays[this.currentInvoiceIndex];
    },

    invoiceNumber() {
      return this.currentInvoice[12];
    },

    invoiceIssueDate() {
      return this.currentInvoice[13];
    },

    invoiceDeliveryDate() {
      return this.currentInvoice[14];
    },

    invoicePaymentDate() {
      return this.currentInvoice[15];
    },

    paymentName() {
      return this.currentInvoice[16];
    },

    invoiceAdditionInfo() {
      return this.currentInvoice[17];
    },

    invoiceCorrectionNumber() {
      return this.isCorrection && this.currentInvoice[28];
    },

    invoiceCorrectionIssueDate() {
      return this.isCorrection && this.currentInvoice[29];
    },

    invoiceCorrectionDeliveryDate() {
      return this.isCorrection && this.currentInvoice[30];
    },

    invoiceCorrectionPaymentDate() {
      return this.isCorrection && this.currentInvoice[31];
    },

    paymentCorrectionName() {
      return this.isCorrection && this.currentInvoice[32];
    },

    invoiceCorrectionReason() {
      return this.isCorrection && this.currentInvoice[33];
    },

    sellerName() {
      return this.currentInvoice[0];
    },

    sellerAddressFirstLine() {
      return `${this.currentInvoice[1]} ${this.currentInvoice[2]}`;
    },

    sellerAddressSecondLine() {
      return `${this.currentInvoice[3]} ${this.currentInvoice[4]}`;
    },

    sellerNIP() {
      return this.currentInvoice[5];
    },

    buyerName() {
      return this.currentInvoice[6];
    },

    buyerAddressFirstLine() {
      return `${this.currentInvoice[7]} ${this.currentInvoice[8]}`;
    },

    buyerAddressSecondLine() {
      return `${this.currentInvoice[9]} ${this.currentInvoice[10]}`;
    },

    buyerNIP() {
      return this.currentInvoice[11];
    },

    ticketLp() {
      return this.currentInvoice[18];
    },

    ticketName() {
      return this.currentInvoice[19];
    },

    itemsCount() {
      return this.currentInvoice[20];
    },

    pretaxPrice() {
      return this.parseStringToNumber(this.currentInvoice[21]);
    },

    pretaxValue() {
      return this.parseStringToNumber(this.currentInvoice[22]);
    },

    taxFactor() {
      return this.parseStringToNumber(this.currentInvoice[23]);
    },

    taxValue() {
      return this.parseStringToNumber(this.currentInvoice[24]);
    },

    bruttoValue() {
      return this.parseStringToNumber(this.currentInvoice[25]);
    },

    paidAmount() {
      return this.parseStringToNumber(this.currentInvoice[26]);
    },

    amountToBePaid() {
      return this.parseStringToNumber(this.currentInvoice[27]);
    },

    correctionTicketLp() {
      return this.isCorrection && this.currentInvoice[34];
    },

    correctionTicketName() {
      return this.isCorrection && this.currentInvoice[35];
    },

    correctionItemsCount() {
      return this.isCorrection && this.currentInvoice[36];
    },

    correctionPretaxPrice() {
      return this.isCorrection && this.parseStringToNumber(this.currentInvoice[37]);
    },

    correctionPretaxValue() {
      return this.isCorrection && this.parseStringToNumber(this.currentInvoice[38]);
    },

    correctionTaxFactor() {
      return this.isCorrection && this.currentInvoice[39];
    },

    correctionTaxValue() {
      return this.isCorrection && this.parseStringToNumber(this.currentInvoice[40]);
    },

    correctionBruttoValue() {
      return this.isCorrection && this.parseStringToNumber(this.currentInvoice[41]);
    },

    correctionPaidAmount() {
      return this.isCorrection && this.parseStringToNumber(this.currentInvoice[42]);
    },

    correctionAmountToBePaid() {
      return this.isCorrection && this.parseStringToNumber(this.currentInvoice[43]);
    },

    finalCorrectionPretaxValue() {
      return this.isCorrection && this.correctionPretaxValue - this.pretaxValue;
    },

    finalCorrectionTaxValue() {
      return this.isCorrection && this.correctionTaxValue - this.taxValue;
    },

    finalCorrectionBruttoValue() {
      return this.isCorrection && this.correctionBruttoValue - this.bruttoValue;
    },

    generatorOptions() {
      return {
        margin: 12,
        filename: `${this.isCorrection ? this.invoiceCorrectionNumber : this.invoiceNumber}.pdf`,
        image: {type: "jpeg", quality: 1},
        html2canvas: {scale: 2},
        jsPDF: {unit: "mm", format: "a4", orientation: "portrait"},
      };
    },
  },

  methods: {
    previousInvoice() {
      this.currentInvoiceIndex = Math.max(0, this.currentInvoiceIndex - 1);
    },

    nextInvoice() {
      this.currentInvoiceIndex = Math.min(this.invoiceArrays.length, this.currentInvoiceIndex + 1);
    },

    onNewCsvFile(event) {
      const file = event.target.files && event.target.files[0];

      var reader = new FileReader();
      reader.readAsText(file, "UTF-8");
      reader.onload = evt => {
        let parsedArrays = CsvParser.toArrays(evt.target.result);
        parsedArrays.splice(0, 2);
        this.invoiceArrays = parsedArrays;
      };
    },

    generateSingleInvoices() {
      html2pdf().set(this.generatorOptions).from(this.$refs.invoice).save();
    },

    generateAllInvoices(initialCall) {
      if (initialCall) {
        this.currentInvoiceIndex = 0;
      }
      if (this.currentInvoiceIndex === this.invoiceArrays.length) {
        //no-op return
      } else {
        html2pdf()
          .set(this.generatorOptions)
          .from(this.$refs.invoice)
          .save()
          .then(result => {
            this.currentInvoiceIndex = this.currentInvoiceIndex + 1;
            this.$nextTick(() => {
              this.generateAllInvoices(false);
            });
          });
      }
    },

    parseStringToNumber(stringNumber) {
      return Number.parseFloat(stringNumber.replace(",", "."));
    },
  },
};
</script>

<style scoped lang="scss">
.upload-button-container {
  height: 56px;
  width: 100%;
  border: 1px solid #828282;
  border-radius: 5px;
  padding: 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;

  &:hover {
    border: 1px solid var(--primary-color);
  }

  &:hover::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    background-color: var(--primary-color);
    opacity: 0.1;
  }
}

.add-icon {
  height: 40px;
}

.invoice-bold-field {
  font-size: 13px;
  font-weight: bold;
}

.invoice-summary-bold-field {
  font-size: 14px;
  font-weight: bold;
}

.invoice-field {
  font-size: 13px;
}

.horizontal-divider {
  height: 1px;
  width: 100%;
  background: #0a0a0a;
}

.empty-cell {
  background: transparent;
  border: none;
}
</style>
