<template>
  <loading-indicator-icon class="anim-infinite-rotate"></loading-indicator-icon>
</template>

<script>
import LoadingIndicatorIcon from "@/assets/icons/loading-indicator.svg";

export default {
  name: "SmallLoadingIndicator",

  components: {LoadingIndicatorIcon},
};
</script>
